import CategoriesAndBrands from "../../../components/admin/categoriesAndBrands";

const AdminCategoriesAndBrands = () => {
  return (
    <>
      <CategoriesAndBrands />
    </>
  );
};

export default AdminCategoriesAndBrands;
