import AsideDesk from "./asideDesk";
import AsideMobile from "./asideMobile";

const Aside = () => {
  return (
    <>
      <AsideMobile />
      <AsideDesk />
    </>
  );
};

export default Aside;
