import { FiHome } from "react-icons/fi";
import { BiNews } from "react-icons/bi";
import { BiBook } from "react-icons/bi";
import { RxExit } from "react-icons/rx";
import { RiFolderHistoryLine } from "react-icons/ri";
import { MdOutlineSchool } from "react-icons/md";
import { BiCart } from "react-icons/bi";
import { MdTitle } from "react-icons/md";
import { MdTextFields } from "react-icons/md";
import { RiVideoLine } from "react-icons/ri";
import { AiOutlineFileProtect } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
import { MdLink } from "react-icons/md";
import { FaListUl } from "react-icons/fa";
import { RiMessage3Line } from "react-icons/ri";
import { BsList } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import { BiErrorAlt } from "react-icons/bi";
import { BiChat } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { MdOutlineAttachMoney } from "react-icons/md";
import { MdFirstPage } from "react-icons/md";
import { MdLastPage } from "react-icons/md";
import { SlOptionsVertical } from "react-icons/sl";
import { HiOutlineSearch } from "react-icons/hi";
import { MdSystemUpdateAlt } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import { BiArchiveIn } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import { FaStop } from "react-icons/fa";
import { BsSpeedometer } from "react-icons/bs";
import { BsFullscreen } from "react-icons/bs";
import { BsFullscreenExit } from "react-icons/bs";
import { AiOutlineProject } from "react-icons/ai";
import { ImQrcode } from "react-icons/im";
import { FaArrowRight } from "react-icons/fa";
import { BiDownArrow, BiUserCircle } from "react-icons/bi";
import { BiLeftArrow } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { SiYoutube } from "react-icons/si";
import { TfiHandDrag } from "react-icons/tfi";
import { MdOutlineDragIndicator, MdExpandMore } from "react-icons/md";
import { IoAdd } from "react-icons/io5";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { TfiUpload } from "react-icons/tfi";
import { BsFileEarmarkExcel } from "react-icons/bs";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import { RiSendPlane2Line } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { CiCircleRemove, CiViewTable } from "react-icons/ci";
import { MdSupportAgent } from "react-icons/md";
import { RxMagnifyingGlass } from "react-icons/rx";
import { GrFormClose } from "react-icons/gr";
import { TbCurrencyReal } from "react-icons/tb";
import { FaUserAlt } from "react-icons/fa";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { RiTruckFill } from "react-icons/ri";
import { MdPayments } from "react-icons/md";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdOutlineHideImage } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoDiamondSharp } from "react-icons/io5";
import { FaDollarSign } from "react-icons/fa";
import { BsFillEmojiHeartEyesFill, BsCartPlus, BsCart } from "react-icons/bs";
import { BsFillTreeFill } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import { SlOptions } from "react-icons/sl";
import { FaTruckLoading } from "react-icons/fa";
import { BiBookmark } from "react-icons/bi";
import { MdOutlineScreenSearchDesktop, MdClose } from "react-icons/md";
import { RiBankCardFill } from "react-icons/ri";
import { CiFaceSmile } from "react-icons/ci";
import { CiFaceMeh } from "react-icons/ci";
import { IoMdInformationCircleOutline } from "react-icons/io";

export default class Icons {
  static Information = IoMdInformationCircleOutline;
  static happyFace = CiFaceSmile;
  static sadFace = CiFaceMeh;
  static Preference = MdOutlineScreenSearchDesktop;
  static Bank = RiBankCardFill;
  static Address = FaTruckLoading;
  static Mark = BiBookmark;
  static Open = SlOptions;
  static Back = HiOutlineArrowLongLeft;
  static MercadoPago = MdOutlineSecurity;
  static Diamond = IoDiamondSharp;
  static Dollar = FaDollarSign;
  static Face = BsFillEmojiHeartEyesFill;
  static FoundImage = MdOutlineHideImage;
  static Sustentable = BsFillTreeFill;
  static CheckV2 = BsCheckCircleFill;
  static Payment = MdPayments;
  static Delivery = RiTruckFill;
  static Bag = RiShoppingBag3Fill;
  static Price = TbCurrencyReal;
  static Close = GrFormClose;
  static Glass = RxMagnifyingGlass;
  static Home = FiHome;
  static News = BiNews;
  static Courses = BiBook;
  static Exit = RxExit;
  static Historic = RiFolderHistoryLine;
  static MyCourses = MdOutlineSchool;
  static Cart = BiCart;
  static Informations = AiOutlineFileProtect;
  static Title = MdTitle;
  static Text = MdTextFields;
  static Video = RiVideoLine;
  static Image = BsImage;
  static Link = MdLink;
  static List = FaListUl;
  static Message = RiMessage3Line;
  static Buttons = BsList;
  static Profile = FaRegUser;
  static Check = BsCheckLg;
  static Error = BiErrorAlt;
  static Chat = BiChat;
  static Arrow = FaArrowRight;
  static Money = MdOutlineAttachMoney;
  static PreviousPage = MdFirstPage;
  static NextPage = MdLastPage;
  static OptionsVertical = SlOptionsVertical;
  static Search = HiOutlineSearch;
  static Update = MdSystemUpdateAlt;
  static Location = GoLocation;
  static Archive = BiArchiveIn;
  static Visible = AiOutlineEye;
  static Invisible = AiOutlineEyeInvisible;
  static Trash = FaTrash;
  static Play = FaPlay;
  static Stop = FaStop;
  static Speed = BsSpeedometer;
  static FullScren = BsFullscreen;
  static NormalFullScren = BsFullscreenExit;
  static Score = AiOutlineProject;
  static QrCode = ImQrcode;
  static Edit = MdModeEditOutline;
  static ArrowTop = MdKeyboardArrowUp;
  static ArrowBottom = MdKeyboardArrowDown;
  static ArrowLeft = BiLeftArrow;
  static ArrowRigth = BiRightArrow;
  static Youtube = SiYoutube;
  static Drag = TfiHandDrag;
  static DragSide = MdOutlineDragIndicator;
  static Add = IoAdd;
  static FileSucess = BsFileEarmarkCheck;
  static FileError = BsFileEarmarkExcel;
  static FileInitial = BsFileEarmarkArrowUp;
  static Upload = TfiUpload;
  static Send = RiSendPlane2Line;
  static Loading = AiOutlineLoading3Quarters;
  static Remove = CiCircleRemove;
  static Support = MdSupportAgent;
  static User = FaUserAlt;
  static Table = CiViewTable;
  static More = MdExpandMore;
  static Right = RiArrowRightSLine;
  static Left = RiArrowLeftSLine;
  static CloseSearch = MdClose;
  static CartBag = BsCart;
  static AddOnCart = BsCartPlus;
}
